import {
    hideMobileMlNav,
    toggleMobileMlNav,
} from "./layoutfunctions.js";


export let setMobileNav = () => {
    $(window).on('resize', function () {
        hideMobileMlNav();
    });
       
    $('.menu-icon-mlnav').on('click', function (e) {
        e.preventDefault();
        toggleMobileMlNav();
    });

    $('#header-wrapper').on('wheel', function() {
            hideMobileMlNav();
    });
    $('header, #nav-wrapper').on('wheel', function(e){
        e.stopPropagation();
    });

    $('#header-wrapper').on('click', function() {
        hideMobileMlNav();
    });
    $('header, #nav-wrapper').on('click', function(e){
        e.stopPropagation();
    });
}