import {
    setParams,
    initScroll,
} from "./layoutfunctions.js";

export let enablePageScroll = () => {
    initScroll();
}

$('.go-to').on('click', function(e){
    e.preventDefault()
    const alias = $(this).attr('data-alias');
    setParams({alias: alias});
});

$('#scroll-top').on('click', function (e) {
    e.preventDefault();
    setParams('');
    // $('html, body').animate({
    //     scrollTop: 0
    // }, 500, function(){
    //     setTimeout(function(){
    //         $('#scroll-top').removeClass('isvis');
    //     }, 100);
    // });
});